import type { NavigationItem as NavigationItemType } from '@type-declarations/navigationItem';

export default function checkMenu(
  menu: NavigationItemType[],
): NavigationItemType[] {
  return menu.filter(menuItem => {
    const hasValidLink =
      menuItem.internalLink?.page?.[0] || menuItem.externalLink;

    if (!hasValidLink) {
      console.warn(
        `Menu item with title "${menuItem.title}" has no valid link`,
      );
    }

    return hasValidLink;
  });
}
