const getInternalUrl = url => {
  if (!url || typeof url !== 'string') return null;

  const ABSOLUTE_PATH_REGEXP = /(http|https|http:|https:|www\.|\.nl|\.com)/gi;
  // Url is a relative path, thus internal.
  if (!url.match(ABSOLUTE_PATH_REGEXP)) return url;

  // All checks failed, url is external
  return null;
};

export default getInternalUrl;
