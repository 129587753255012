import useStore from '@hooks/useStore';
import LogoAnchr from '@svg/logo-anchr.svg';
import LogoBridg from '@svg/logo-bridg.svg';
import LogoNimbl from '@svg/logo-nimbl.svg';
import { isBridgDomain, isNimblDomain } from '@utils/getDomain';

interface Props {
  className?: string;
}

export default function Logo({ className }: Props) {
  const {
    store: { host },
  } = useStore();

  let LogoSvg = LogoAnchr;

  switch (true) {
    case isNimblDomain(host):
      LogoSvg = LogoNimbl;
      break;
    case isBridgDomain(host):
      LogoSvg = LogoBridg;
      break;
    default:
  }

  return <LogoSvg className={className} />;
}
