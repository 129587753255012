import { LOCALES } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import SelectInput from '@molecules/Inputs/SelectInput';
import { Locale } from '@type-declarations/prepr';
import { setCookie } from '@utils/clientCookies';
import { isBridgDomain, isNimblDomain } from '@utils/getDomain';
import getInternalRoute from '@utils/getInternalRoute';
import { useRouter } from 'next/router';
import { ChangeEvent } from 'react';

const optionsAnchr = [
  {
    label: 'Nederlands (nl)',
    value: 'nl',
  },
  {
    label: 'Nederlands (be)',
    value: 'be',
  },
  {
    label: 'English',
    value: 'en',
  },
];

const optionsNimbl = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Nederlands',
    value: 'nl',
  },
];

const optionsBridg = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Nederlands',
    value: 'nl',
  },
];

const getOptions = (host: string) => {
  switch (true) {
    case isNimblDomain(host):
      return optionsNimbl;
    case isBridgDomain(host):
      return optionsBridg;
    default:
      return optionsAnchr;
  }
};

export default function LanguageSwitch() {
  const t = useTranslations();

  const {
    store: { page, locale, host },
    setStore,
  } = useStore();
  const { push } = useRouter();

  const options = getOptions(host);

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value as Locale;
    const localization = page?.localizations?.find(
      loc => loc.locale === LOCALES[value],
    );

    /* Redirect to homepage if no localization for selected language is available */
    const route = localization
      ? getInternalRoute({
          internalLink: { page: [localization] },
          locale: value,
        })
      : '/';

    setCookie('NEXT_LOCALE', value);
    setStore(store => ({ ...store, locale: value }));
    push(route, undefined, { locale: value });
  };

  if (options.length <= 1) return null;

  return (
    <SelectInput
      id="language-switch"
      value={locale}
      options={options}
      onChange={handleSelect}
      label={t.selectLanguage}
    />
  );
}
