import '@styles/_base.scss';
import '@atoms/NProgress/NProgress.scss';

import Favicons from '@atoms/Favicons/Favicons';
import NProgress from '@atoms/NProgress/NProgress';
import { DOMAIN_BRIDG, DOMAIN_NIMBL } from '@constants/constants';
import { StoreProvider } from '@hooks/useStore';
import PreviewMessage from '@molecules/PreviewMessage/PreviewMessage';
import { GoogleTagManager } from '@next/third-parties/google';
import BaseTemplate from '@templates/BaseTemplate/BaseTemplate';
import { Page } from '@type-declarations/page';
import { getDomain } from '@utils/getDomain';
import getNprogressColor from '@utils/getNprogressColor';
import clsx from 'clsx';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import localFont from 'next/font/local';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';

export type NextPageWithLayout<Props = object, InitialProps = Props> = NextPage<
  Props,
  InitialProps
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface PageProps {
  preview?: boolean;
  page: Page;
  host: string;

  [key: string]: unknown;
}

type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

const SohneFont = localFont({
  variable: '--font-family-heading',
  src: [
    {
      path: '../public/fonts/soehne-halbfett.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/soehne-kraftig.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
});

const FiraSansFont = localFont({
  variable: '--font-family-base',
  src: [
    {
      path: '../public/fonts/FiraSans-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/FiraSans-LightItalic.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: '../public/fonts/FiraSans-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/FiraSans-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/FiraSans-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../public/fonts/FiraSans-SemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/FiraSans-SemiBoldItalic.woff2',
      weight: '600',
      style: 'italic',
    },
    {
      path: '../public/fonts/FiraSans-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/FiraSans-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
});

const gtmId = (domain: string) => {
  switch (domain) {
    case DOMAIN_NIMBL:
      return process.env.GTM_CODE_NIMBL;
    case DOMAIN_BRIDG:
      return process.env.GTM_CODE_BRIDG;
    default:
      return process.env.GTM_CODE_ANCHR;
  }
};

const preprTrackingToken = (domain: string) => {
  switch (domain) {
    case DOMAIN_NIMBL:
      return process.env.PREPR_TRACKING_TOKEN_NIMBL;
    case DOMAIN_BRIDG:
      return process.env.PREPR_TRACKING_TOKEN_BRIDG;
    default:
      return process.env.PREPR_TRACKING_TOKEN_ANCHR;
  }
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const { preview, page, host } = pageProps;
  const domain = getDomain(host);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = async () => {
      // We need a timeout here to give the route change some time to update the prepr-id meta
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(resolve => setTimeout(resolve, 1000));
      prepr('event', 'View');
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    (pageContent => {
      // Hide default layout
      if (pageProps.page?.typename === 'BrandPage') {
        return pageContent;
      }

      return (
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        <BaseTemplate {...(pageProps.page || {})} domain={domain}>
          {pageContent}
        </BaseTemplate>
      );
    });

  return (
    <>
      <Head>
        <Favicons domain={domain} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        {page?.id && <meta property="prepr:id" content={page.id} />}
        <style>{`
          :root {
            --color-nprogress: ${getNprogressColor(domain)};
          }
        `}</style>
      </Head>

      <Script
        id="prepr-tracking"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              ! function (e, t, p, r, n, a, s) {
              e[r] || ((n = e[r] = function () {
              n.process ? n.process.apply(n, arguments) : n.queue.push(arguments)
              }).queue = [], n.t = +new Date, (a = t.createElement(p)).async = 1, a.src = "https://cdn.tracking.prepr.io/js/prepr_v2.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (s = t.getElementsByTagName(p)[0]).parentNode.insertBefore(a, s))
              }(window, document, "script", "prepr"), prepr("init", "${preprTrackingToken(domain)}"), prepr("event", "pageload");
            `,
        }}
      />

      <GoogleTagManager gtmId={gtmId(domain) as string} />

      {/* @ts-expect-error TODO: Sync pageprops with store props */}
      <StoreProvider store={pageProps}>
        <div
          className={clsx(
            'nextFonts',
            SohneFont.variable,
            FiraSansFont.variable,
          )}
          data-theme={domain}
        >
          <NProgress />
          <PreviewMessage preview={preview} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {getLayout(<Component {...pageProps} />)}
        </div>
      </StoreProvider>
    </>
  );
}
