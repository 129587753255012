import {
  DOMAIN_BRIDG,
  DOMAIN_GENERIC,
  DOMAIN_NIMBL,
} from '@constants/constants';

interface Props {
  domain: string;
}

export default function Favicons({ domain }: Props) {
  if (domain === DOMAIN_GENERIC) return null;

  if (domain === DOMAIN_BRIDG) {
    return (
      <>
        <link rel="icon" href="/favicons/bridg/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          href="/favicons/bridg/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/bridg/apple-touch-icon.png"
        />
        <link rel="manifest" href="/favicons/bridg/site.webmanifest" />
      </>
    );
  }

  if (domain === DOMAIN_NIMBL) {
    return (
      <>
        <link rel="icon" href="/favicons/nimbl/favicon.ico" />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/favicons/nimbl/favicon.svg"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicons/nimbl/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/nimbl/apple-touch-icon.png"
        />
        <link rel="manifest" href="/favicons/nimbl/site.webmanifest" />
      </>
    );
  }

  return (
    <>
      <link rel="icon" href="/favicons/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link rel="manifest" href="/favicons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicons/safari-pinned-tab.svg"
        color="#66de80"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
    </>
  );
}
