import useLocale from '@hooks/useLocale';

export const translations = {
  nl: {
    scrollToBottom: 'Scroll naar beneden',
    clientLogo: 'Logo van klant',
    skiplink: 'Ga naar de hoofdinhoud',
    previous: 'Vorige',
    next: 'Volgende',
    page: {
      CasePage: 'Case',
      ContentPage: 'Pagina',
      EventPage: 'Evenement',
      NewsPage: 'Nieuws',
      SolutionPage: 'Oplossing',
    },
    online: 'Online',
    viewCompany: 'Bekijk bedrijf',
    viewEvent: 'Bekijk evenement',
    upcomingEvents: 'Komende evenementen',
    pastEvents: 'Eerdere evenementen',
    eventIsInPast: 'Dit evenement is al geweest',
    viewVacancy: 'Bekijk de vacature',
    MONDAY: 'Maandag',
    TUESDAY: 'Dinsdag',
    WEDNESDAY: 'Woensdag',
    THURSDAY: 'Donderdag',
    FRIDAY: 'Vrijdag',
    SATURDAY: 'Zaterdag',
    SUNDAY: 'Zondag',
    nothingFoundTitle: 'Geen items gevonden',
    nothingFoundText: 'Probeer het later nog eens.',
    noNewsFoundTitle: 'Geen nieuws gevonden',
    noNewsFoundText:
      'Op dit moment hebben we geen nieuws. Kom later terug voor meer updates.',
    noCasesFoundTitle: 'Geen cases gevonden',
    noCasesFoundText:
      'Op dit moment hebben we geen cases beschikbaar. Kom later terug voor meer updates.',
    noEventsFoundTitle: 'Geen evenementen gevonden',
    noEventsFoundText:
      'Op dit moment zijn er geen evenementen beschikbaar. Kom later terug voor meer updates.',
    noVacanciesFoundTitle: 'Geen vacatures gevonden',
    noVacanciesFoundText:
      'Op dit moment zijn er geen vacatures beschikbaar. Kom later terug voor meer updates.',
    notFoundPageTitle: 'Pagina niet gevonden',
    notFoundPageHeroTitle:
      'Oeps... Het lijkt erop dat de pagina die je zoekt niet kan worden gevonden',
    notFoundPageCtaText: 'Ga naar homepagina',
    errorPageTitle: 'Er is iets fout gegaan',
    errorPageHeroTitle: 'Oeps... Het lijkt erop dat er iets is foutgegaan.',
    errorPageHeroText:
      'Ga naar onze homepage en zoek opnieuw. Of neem direct event contact op, we helpen je graag verder.',
    allXResults: 'Alle {total} resultaten',
    noResults: 'Geen resultaten',
    oneResult: 'Eén resultaat',
    allXVacancies: 'Alle {total} vacatures',
    noVacancies: 'Geen vacatures',
    oneVacancy: 'Eén vacature',
    selectLanguage: 'Selecteer een taal',
    showMore: 'Toon meer',
    readMore: 'Lees meer',
    generalCase: 'Algemeen',
    breadcrumbs: {
      backTo: 'Terug naar',
      home: 'Home',
      news: 'Nieuws',
      cases: 'Cases',
      solutions: 'Oplossingen',
      vacancies: 'Vacatures',
      events: 'Evenementen',
      companies: 'Bedrijven',
    },
    signUp: 'Schrijf me in',
    tagsFilter: {
      showAll: 'Toon alles',
      generalNewsAnchr: 'Anchr Life',
      generalNews: 'Algemeen',
    },
    optional: 'optioneel',
    selectFile: 'Selecteer een bestand',
    fileToLarge: 'Het bestand is te groot',
    apply: 'Solliciteer',
    applyNow: 'Solliciteer nu',
    applicationForm: {
      title: 'Solliciteer als {jobTitle}',
      introduction:
        'Bedankt voor je interesse. Vul je gegevens in, upload je CV en laat ons weten wat je motiveert.',
      fieldSets: {
        personalInformation: 'Persoonlijke informatie',
        cv: 'Voeg je CV toe',
        motivation: 'Voeg je motivatie toe',
        comment: 'Wil je nog iets kwijt?',
        termsAndConditions: 'Wettelijke overeenkomsten',
      },
      fields: {
        firstName: {
          label: 'Voornaam',
          placeholder: 'Jan',
        },
        lastName: {
          label: 'Achternaam',
          placeholder: 'Gillione',
        },
        email: {
          label: 'E-mailadres',
          placeholder: 'jouwmooi@emailadres.nl',
        },
        phone: {
          label: 'Telefoonnummer',
          placeholder: 'Als je dat oké vindt...',
          explanation: 'We gebruiken dit alleen om contact met je op te nemen.',
        },
        comment: {
          label: 'Opmerking',
          placeholder: 'Opmerking',
        },
        cv: {
          label: 'Upload hier jouw CV',
        },
        motivation: {
          label: 'Waarom wil je bij {company} werken?',
          placeholder: 'Motivatie',
          showTextAreaText: 'Of schrijf je motivatie in plaats daarvan hier.',
          hideTextAreaText: 'Of upload een bestand met je motivatie.',
        },
        termsAndConditions: {
          label:
            'Ik heb het <a href="/privacybeleid" target="_blank">privacybeleid</a> gelezen en ga hiermee akkoord.',
        },
      },
      submit: 'Verstuur sollicitatie',
    },
    fileAccept: 'Alleen doc, docx of pdf',
    maxFileSize: 'max. grootte: {size}MB',
    department: 'Afdeling',
    company: 'Bedrijf',
    salary: 'Salaris',
    location: 'Locatie',
    close: 'sluit',
    date: 'Datum',
    time: 'Tijdstip',
    language: 'Taal',
    targetAudience: 'Doelgroep',
    costs: 'Deelnamekosten',
    watch: 'Bekijk',
    somethingWentWrong: 'Er is iets misgegaan',
    invalidEmail: 'Ongeldig e-mailadres',
    inputRequired: 'Dit veld is verplicht',
    noFileSelected: 'Geen bestand geselecteerd',
    mustBeTrue: 'Je moet dit aanvinken om door te kunnen gaan',
    invalidFileType: 'Ongeldig bestandstype',
    scrollToTop: 'Scroll naar boven',
    recaptchaValidationError: 'Recaptcha validatie fout. Probeer het opnieuw.',
    recaptchaLoadingError:
      'Recaptcha kan niet worden geladen. Probeer het opnieuw.',
    readAll: 'Ontdek meer',
    playAudio: 'Speel audio af',
    pauseAudio: 'Pauzeer audio',
    videoConsentText: 'Accepteer marketingcookies om deze video te bekijken',
    videoManageCookies: 'Cookies beheren',
  },
  en: {
    scrollToBottom: 'Scroll to bottom',
    clientLogo: 'Client logo',
    skiplink: 'Skip to main content',
    previous: 'Previous',
    next: 'Next',
    page: {
      CasePage: 'Case',
      ContentPage: 'Page',
      EventPage: 'Event',
      NewsPage: 'News',
      SolutionPage: 'Solution',
    },
    online: 'Online',
    viewCompany: 'View company',
    viewEvent: 'View event',
    upcomingEvents: 'Upcoming events',
    pastEvents: 'Past events',
    eventIsInPast: 'This event has already passed',
    viewVacancy: 'View vacancy',
    noEventsFoundTitle: 'No events found',
    noEventsFoundText: 'At this moment there are no events available.',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    nothingFoundTitle: 'No items found',
    nothingFoundText: 'Try again later.',
    noNewsFoundTitle: 'No news found',
    noNewsFoundText:
      'At this moment we have no news. Come back later for more updates.',
    noCasesFoundTitle: 'No cases found',
    noCasesFoundText:
      'At this moment we have no cases available. Come back later for more updates.',
    noVacanciesFoundTitle: 'No vacancies found',
    noVacanciesFoundText:
      'At this moment we have no vacancies available. Come back later for more updates.',
    notFoundPageTitle: 'Page not found',
    notFoundPageHeroTitle:
      'Oops... It seems the page you are looking for cannot be found',
    notFoundPageCtaText: 'Go to homepage',
    errorPageTitle: 'Something went wrong',
    errorPageHeroTitle: 'Oops... It seems something went wrong.',
    errorPageHeroText:
      'Go to our homepage and search again. Or contact us directly, we are happy to help.',
    allXResults: 'All {total} results',
    noResults: 'No results',
    oneResult: 'One result',
    allXVacancies: 'All {total} vacancies',
    noVacancies: 'No vacancies',
    oneVacancy: 'One vacancy',
    selectLanguage: 'Select a language',
    showMore: 'Show more',
    readMore: 'Read more',
    generalCase: 'General',
    breadcrumbs: {
      backTo: 'Back to',
      home: 'Home',
      news: 'News',
      cases: 'Cases',
      solutions: 'Solutions',
      vacancies: 'Vacancies',
      events: 'Events',
      companies: 'Companies',
    },
    signUp: 'Sign me up',
    tagsFilter: {
      showAll: 'Show all',
      generalNewsAnchr: 'Anchr Life',
      generalNews: 'General',
    },
    optional: 'optional',
    selectFile: 'Select a file',
    fileToLarge: 'The file is too large',
    apply: 'Apply',
    applyNow: 'Apply now',
    applicationForm: {
      title: 'Apply for {jobTitle}',
      introduction:
        'Thank you for your interest. Fill in your details, upload your CV and let us know what motivates you.',
      fieldSets: {
        personalInformation: 'Personal information',
        cv: 'Add your CV',
        motivation: 'Add your motivation',
        comment: 'Do you have anything else to say?',
        termsAndConditions: 'Legal agreements',
      },
      fields: {
        firstName: {
          label: 'First name',
          placeholder: 'John',
        },
        lastName: {
          label: 'Last name',
          placeholder: 'Doe',
        },
        email: {
          label: 'E-mail address',
          placeholder: 'Your beautiful email address',
        },
        phone: {
          label: 'Phone number',
          placeholder: "If you're okay with that...",
          explanation: 'We only use this to contact you.',
        },
        comment: {
          label: 'Comment',
          placeholder: 'Comment',
        },
        cv: {
          label: 'Upload your CV here',
        },
        motivation: {
          label: 'Why do you want to work at {company}?',
          placeholder: 'Motivation',
          showTextAreaText: 'Or write your motivation here instead.',
          hideTextAreaText: 'Or upload a file with your motivation.',
        },
        termsAndConditions: {
          label:
            'I have read and agree to the <a href="/privacy-policy" target="_blank">privacy policy</a>.',
        },
      },
      submit: 'Submit application',
    },
    fileAccept: 'Only doc, docx or pdf',
    maxFileSize: 'max. size: {size}MB',
    department: 'Department',
    company: 'Company',
    salary: 'Salary',
    location: 'Location',
    close: 'close',
    date: 'Date',
    time: 'Time',
    language: 'Language',
    targetAudience: 'Target audience',
    costs: 'Participation costs',
    watch: 'View',
    somethingWentWrong: 'Something went wrong',
    invalidEmail: 'Invalid email address',
    inputRequired: 'This field is required',
    noFileSelected: 'No file selected',
    mustBeTrue: 'You must check this to continue',
    invalidFileType: 'Invalid file type',
    scrollToTop: 'Scroll to top',
    recaptchaValidationError: 'Recaptcha validation error. Please try again.',
    recaptchaLoadingError: 'Recaptcha could not be loaded. Please try again.',
    readAll: 'Discover more',
    playAudio: 'Play audio',
    pauseAudio: 'Pause audio',
    videoConsentText: 'Please accept marketing cookies to view this video',
    videoManageCookies: 'Manage cookies',
  },
  fr: {
    scrollToBottom: 'Faites défiler vers le bas',
    clientLogo: 'Logo du client',
    skiplink: 'Aller au contenu principal',
    previous: 'Précédent',
    next: 'Suivant',
    page: {
      CasePage: 'Cas',
      ContentPage: 'Page',
      EventPage: 'Événement',
      NewsPage: 'Nouvelles',
      SolutionPage: 'Solution',
    },
    online: 'En ligne',
    viewCompany: 'Voir l’entreprise',
    viewEvent: "Voir l'événement",
    upcomingEvents: 'Événements à venir',
    pastEvents: 'Événements passés',
    eventIsInPast: 'Cet événement est déjà passé',
    viewVacancy: 'Voir la vacance',
    noEventsFoundTitle: 'Aucun événement trouvé',
    noEventsFoundText: 'Pour le moment, aucun événement n’est disponible.',
    MONDAY: 'Lundi',
    TUESDAY: 'Mardi',
    WEDNESDAY: 'Mercredi',
    THURSDAY: 'Jeudi',
    FRIDAY: 'Vendredi',
    SATURDAY: 'Samedi',
    SUNDAY: 'Dimanche',
    nothingFoundTitle: 'Aucun élément trouvé',
    nothingFoundText: 'Réessayez plus tard.',
    noNewsFoundTitle: 'Aucune nouvelle trouvée',
    noNewsFoundText:
      'Pour le moment, nous n’avons pas de nouvelles. Revenez plus tard pour plus de mises à jour.',
    noCasesFoundTitle: 'Aucun cas trouvé',
    noCasesFoundText:
      'Pour le moment, nous n’avons pas de cas disponibles. Revenez plus tard pour plus de mises à jour.',
    noVacanciesFoundTitle: 'Aucune offre d’emploi trouvée',
    noVacanciesFoundText:
      'Pour le moment, nous n’avons pas d’offres d’emploi disponibles. Revenez plus tard pour plus de mises à jour.',
    notFoundPageTitle: 'Page non trouvée',
    notFoundPageHeroTitle:
      'Oups... La page que vous cherchez semble introuvable',
    notFoundPageCtaText: 'Aller à la page d’accueil',
    errorPageTitle: "Quelque chose s'est mal passé",
    errorPageHeroTitle: 'Oups... Il semble qu’il y ait eu une erreur.',
    errorPageHeroText:
      'Allez sur notre page d’accueil et cherchez à nouveau. Ou contactez-nous directement, nous sommes heureux de vous aider.',
    allXResults: 'Tous les {total} résultats',
    noResults: 'Aucun résultat',
    oneResult: 'Un résultat',
    allXVacancies: 'Toutes les {total} offres d’emploi',
    noVacancies: 'Aucune offre d’emploi',
    oneVacancy: 'Une offre d’emploi',
    selectLanguage: 'Sélectionnez une langue',
    showMore: 'Afficher plus',
    readMore: 'Lire la suite',
    generalCase: 'Général',
    breadcrumbs: {
      backTo: 'Retour à',
      home: 'Home',
      news: 'Nouvelles',
      cases: 'Cas',
      solutions: 'Solutions',
      vacancies: 'Emplois vacants',
      events: 'Événements',
      companies: 'Entreprises',
    },
    signUp: 'Inscrivez-moi',
    tagsFilter: {
      showAll: 'Tout afficher',
      generalNewsAnchr: 'Anchr Life',
      generalNews: 'Général',
    },
    optional: 'optionnel',
    selectFile: 'Sélectionner un fichier',
    fileToLarge: 'Le fichier est trop volumineux',
    apply: 'Postuler',
    applyNow: 'Postulez maintenant',
    applicationForm: {
      title: 'Postulez pour {jobTitle}',
      introduction:
        'Merci de votre intérêt. Remplissez vos coordonnées, téléchargez votre CV et dites-nous ce qui vous motive.',
      fieldSets: {
        personalInformation: 'Informations personnelles',
        cv: 'Ajoutez votre CV',
        motivation: 'Ajoutez votre motivation',
        comment: 'Avez-vous quelque chose d’autre à dire ?',
        termsAndConditions: 'Accords légaux',
      },
      fields: {
        firstName: {
          label: 'Prénom',
          placeholder: 'Jean',
        },
        lastName: {
          label: 'Nom de famille',
          placeholder: 'Dupont',
        },
        email: {
          label: 'Adresse e-mail',
          placeholder: 'Adresse e-mail',
        },
        phone: {
          label: 'Numéro de téléphone',
          placeholder: "Si vous êtes d'accord...",
          explanation: 'Nous utilisons cela uniquement pour vous contacter.',
        },
        comment: {
          label: 'Commentaire',
          placeholder: 'Commentaire',
        },
        cv: {
          label: 'Téléchargez votre CV ici',
        },
        motivation: {
          label: 'Pourquoi voulez-vous travailler chez {company} ?',
          placeholder: 'Motivation',
          showTextAreaText: 'Ou écrivez votre motivation ici à la place.',
          hideTextAreaText: 'Ou téléchargez un fichier avec votre motivation.',
        },
        termsAndConditions: {
          label:
            'J’ai lu et j’accepte la <a href="/politique-de-confidentialite" target="_blank">politique de confidentialité</a>.',
        },
      },
      submit: 'Envoyer la candidature',
    },
    fileAccept: 'Uniquement doc, docx ou pdf',
    maxFileSize: 'taille max. : {size}Mo',
    department: 'Département',
    company: 'Entreprise',
    salary: 'Salaire',
    location: 'Emplacement',
    close: 'fermer',
    date: 'Date',
    time: 'Heure',
    language: 'Langue',
    targetAudience: 'Public cible',
    costs: 'Coûts de participation',
    watch: 'Regarder',
    somethingWentWrong: "Quelque chose s'est mal passé",
    invalidEmail: 'Adresse e-mail invalide',
    inputRequired: 'Ce champ est obligatoire',
    noFileSelected: 'Aucun fichier sélectionné',
    mustBeTrue: 'Vous devez cocher ceci pour continuer',
    invalidFileType: 'Type de fichier invalide',
    scrollToTop: 'Défiler vers le haut',
    recaptchaValidationError:
      'Erreur de validation Recaptcha, veuillez réessayer.',
    recaptchaLoadingError:
      'Recaptcha n’a pas pu être chargé. Veuillez réessayer.',
    readAll: 'Découvrir plus',
    playAudio: 'Lire l’audio',
    pauseAudio: 'Mettre en pause l’audio',
    videoConsentText:
      'Veuillez accepter les cookies marketing pour voir cette vidéo',
    videoManageCookies: 'Gérer les cookies',
  },
  be: {
    scrollToBottom: 'Scroll naar beneden',
    clientLogo: 'Logo van klant',
    skiplink: 'Ga naar de hoofdinhoud',
    previous: 'Vorige',
    next: 'Volgende',
    page: {
      CasePage: 'Case',
      ContentPage: 'Pagina',
      EventPage: 'Evenement',
      NewsPage: 'Nieuws',
      SolutionPage: 'Oplossing',
    },
    online: 'Online',
    viewCompany: 'Bekijk bedrijf',
    viewEvent: 'Bekijk evenement',
    upcomingEvents: 'Komende evenementen',
    pastEvents: 'Eerdere evenementen',
    eventIsInPast: 'Dit evenement is al geweest',
    viewVacancy: 'Bekijk de vacature',
    noEventsFoundTitle: 'Geen evenementen gevonden',
    noEventsFoundText:
      'Op dit moment zijn er geen evenementen beschikbaar. Kom later terug voor meer updates.',
    MONDAY: 'Maandag',
    TUESDAY: 'Dinsdag',
    WEDNESDAY: 'Woensdag',
    THURSDAY: 'Donderdag',
    FRIDAY: 'Vrijdag',
    SATURDAY: 'Zaterdag',
    SUNDAY: 'Zondag',
    nothingFoundTitle: 'Geen items gevonden',
    nothingFoundText: 'Probeer het later nog eens.',
    noNewsFoundTitle: 'Geen nieuws gevonden',
    noNewsFoundText:
      'Op dit moment hebben we geen nieuws. Kom later terug voor meer updates.',
    noCasesFoundTitle: 'Geen cases gevonden',
    noCasesFoundText:
      'Op dit moment hebben we geen cases beschikbaar. Kom later terug voor meer updates.',
    noVacanciesFoundTitle: 'Geen vacatures gevonden',
    noVacanciesFoundText:
      'Op dit moment hebben we geen vacatures beschikbaar. Kom later terug voor meer updates.',
    notFoundPageTitle: 'Pagina niet gevonden',
    notFoundPageHeroTitle:
      'Oeps... Het lijkt erop dat de pagina die je zoekt niet kan worden gevonden',
    notFoundPageCtaText: 'Ga naar homepagina',
    errorPageTitle: 'Er is iets fout gegaan',
    errorPageHeroTitle: 'Oeps... Het lijkt erop dat er iets is foutgegaan.',
    errorPageHeroText:
      'Ga naar onze homepage en zoek opnieuw. Of neem direct event contact op, we helpen je graag verder.',
    allXResults: 'Alle {total} resultaten',
    noResults: 'Geen resultaten',
    oneResult: 'Eén resultaat',
    allXVacancies: 'Alle {total} vacatures',
    noVacancies: 'Geen vacatures',
    oneVacancy: 'Eén vacature',
    selectLanguage: 'Selecteer een taal',
    showMore: 'Toon meer',
    readMore: 'Lees meer',
    generalCase: 'Algemeen',
    breadcrumbs: {
      backTo: 'Terug naar',
      home: 'Home',
      news: 'Nieuws',
      cases: 'Cases',
      solutions: 'Oplossingen',
      vacancies: 'Vacatures',
      events: 'Evenementen',
      companies: 'Bedrijven',
    },
    signUp: 'Schrijf me in',
    tagsFilter: {
      showAll: 'Toon alles',
      generalNewsAnchr: 'Anchr Life',
      generalNews: 'Algemeen',
    },
    optional: 'optioneel',
    selectFile: 'Selecteer een bestand',
    fileToLarge: 'Het bestand is te groot',
    apply: 'Solliciteer',
    applyNow: 'Solliciteer nu',
    applicationForm: {
      title: 'Solliciteer als {jobTitle}',
      introduction:
        'Bedankt voor je interesse. Vul je gegevens in, upload je CV en laat ons weten wat je motiveert.',
      fieldSets: {
        personalInformation: 'Persoonlijke informatie',
        cv: 'Voeg je CV toe',
        motivation: 'Voeg je motivatie toe',
        comment: 'Wil je nog iets kwijt?',
        termsAndConditions: 'Wettelijke overeenkomsten',
      },
      fields: {
        firstName: {
          label: 'Voornaam',
          placeholder: 'Jan',
        },
        lastName: {
          label: 'Achternaam',
          placeholder: 'Gillione',
        },
        email: {
          label: 'E-mailadres',
          placeholder: 'jouwmooi@emailadres.be',
        },
        phone: {
          label: 'Telefoonnummer',
          placeholder: 'Als je dat oké vindt...',
          explanation: 'We gebruiken dit alleen om contact met je op te nemen.',
        },
        comment: {
          label: 'Opmerking',
          placeholder: 'Opmerking',
        },
        cv: {
          label: 'Upload hier jouw CV',
        },
        motivation: {
          label: 'Waarom wil je bij {company} werken?',
          placeholder: 'Motivatie',
          showTextAreaText: 'Of schrijf je motivatie in plaats daarvan hier.',
          hideTextAreaText: 'Of upload een bestand met je motivatie.',
        },
        termsAndConditions: {
          label:
            'Ik heb het <a href="/privacybeleid" target="_blank">privacybeleid</a> gelezen en ga hiermee akkoord.',
        },
      },
      submit: 'Verstuur sollicitatie',
    },
    fileAccept: 'Alleen doc, docx of pdf',
    maxFileSize: 'max. grootte: {size}MB',
    department: 'Afdeling',
    company: 'Bedrijf',
    salary: 'Salaris',
    location: 'Locatie',
    close: 'sluit',
    date: 'Datum',
    time: 'Tijdstip',
    language: 'Taal',
    targetAudience: 'Doelgroep',
    costs: 'Deelnamekosten',
    watch: 'Bekijk',
    somethingWentWrong: 'Er is iets misgegaan',
    invalidEmail: 'Ongeldig e-mailadres',
    inputRequired: 'Dit veld is verplicht',
    noFileSelected: 'Geen bestand geselecteerd',
    mustBeTrue: 'Je moet dit aanvinken om door te kunnen gaan',
    invalidFileType: 'Ongeldig bestandstype',
    scrollToTop: 'Scroll naar boven',
    recaptchaValidationError: 'Recaptcha validatie fout, probeer het opnieuw.',
    recaptchaLoadingError:
      'Recaptcha kan niet worden geladen, probeer het opnieuw.',
    readAll: 'Ontdek meer',
    playAudio: 'Speel audio af',
    pauseAudio: 'Pauzeer audio',
    videoConsentText: 'Accepteer marketingcookies om deze video te bekijken',
    videoManageCookies: 'Cookies beheren',
  },
  default: {},
} as const;

export default function useTranslations() {
  const locale = useLocale();
  return translations[locale];
}
