import Link from '@atoms/Link/Link';
import Logo from '@atoms/Logo/Logo';
import useFocusTrap from '@hooks/useFocusTrap';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import { isGenericDomain } from '@utils/getDomain';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Headroom from 'react-headroom';

import styles from './Header.module.scss';
import Menu from './Menu';
import MenuToggle from './MenuToggle';
import Navbar from './Navbar';

const MENU_ID = 'header-main-menu';

interface EventProps {
  screenX?: number;
  screenY?: number;
}

function Header() {
  const [menuIsShown, setMenuIsShown] = useState(false);

  const ref = useRef<HTMLElement>(null);
  const menuToggleRef = useRef<HTMLButtonElement>(null);

  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(
    ref,
    menuToggleRef,
  );
  const router = useRouter();
  const {
    store: { config, locale, page, host },
  } = useStore();
  const t = useTranslations();

  const focusFirstElement = () => {
    const firstNavElement = document.querySelector(
      '[data-id="first-menu-element"] a',
    ) as HTMLElement; // Explicitly typecast to HTMLElement
    setTimeout(() => firstNavElement?.focus(), 0);
  };

  const toggleMenu = (e: EventProps = {}) => {
    setMenuIsShown(!menuIsShown);
    // Check if the event is a keyboard event
    if (!menuIsShown && e?.screenX === 0 && e?.screenY === 0) {
      focusFirstElement();
    }
  };

  useEffect(() => {
    if (menuIsShown) {
      window.document.documentElement.style.overflow = 'hidden';
      enableFocusTrap();
    } else {
      window.document.documentElement.style.overflow = '';
      disableFocusTrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsShown]);

  useEffect(() => {
    if (menuIsShown) {
      toggleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, locale]);

  // 404 and 500 pages don't have a header but need the same header spacing
  if (isGenericDomain(host))
    return (
      <Headroom
        tag="header"
        disable={menuIsShown}
        className={clsx(styles.header, menuIsShown && styles.menuIsShown)}
      >
        <nav className={clsx('u-container', styles.container)} ref={ref} />
      </Headroom>
    );

  return (
    <Headroom
      tag="header"
      disable={menuIsShown}
      className={clsx(styles.header, menuIsShown && styles.menuIsShown)}
    >
      <span className={styles.backdrop} />
      <nav className={clsx('u-container', styles.container)} ref={ref}>
        <div className={styles.topBar}>
          <div className={styles.logoContainer}>
            <Link href="/" className={styles.logo} aria-label="Home">
              <Logo className={styles.logoSvg} />
            </Link>
          </div>

          {!!config?.mainMenu && !menuIsShown && (
            <Navbar
              menu={config.mainMenu.primaryMenu}
              currentPageId={page?.id}
            />
          )}

          <div className={styles.menuToggleContainer}>
            <p className={clsx('typography-paragraph-xs', styles.close)}>
              {t.close}
            </p>
            <MenuToggle
              ref={menuToggleRef}
              onClick={toggleMenu}
              menuIsShown={menuIsShown}
              menuID={MENU_ID}
            />
          </div>
        </div>

        {!!config?.mainMenu && (
          <Menu
            mainMenu={config.mainMenu}
            menuIsShown={menuIsShown}
            currentPageId={page?.id}
            id={MENU_ID}
          />
        )}
      </nav>
    </Headroom>
  );
}

export default Header;
