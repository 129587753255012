/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import Icon from '@atoms/Icon/Icon';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import LanguageSwitch from '@molecules/LanguageSwitch/LanguageSwitch';
import { MainMenu } from '@type-declarations/mainMenu';
import checkMenu from '@utils/checkMenu';
import clsx from 'clsx';

import styles from './Menu.module.scss';

interface Props {
  mainMenu: MainMenu;
  menuIsShown: boolean;
  currentPageId?: string;
  id: string;
}

export default function Menu({
  mainMenu,
  menuIsShown,
  currentPageId,
  id,
}: Props) {
  const primaryMenu = checkMenu(mainMenu.primaryMenu);
  const secondaryMenu = checkMenu(mainMenu.secondaryMenu);

  return (
    <div className={clsx(styles.menu, menuIsShown && styles.isOpen)} id={id}>
      <div className={clsx(styles.container, 'u-container')}>
        <ul className={clsx(styles.primaryContainer, 'u-list-clean')}>
          {primaryMenu.map((menuItem, index) => {
            const linkPageID = menuItem.internalLink?.page[0]?.id;
            const isActive = linkPageID === currentPageId;

            return (
              <li
                className={clsx(styles.item, isActive && styles.active)}
                key={menuItem.id}
                data-id={index === 0 ? 'first-menu-element' : undefined}
              >
                <Icon icon="arrow-right" className={styles.icon} aria-hidden />
                {menuItem.typename === 'MenuItem' && (
                  <NavigationItem item={menuItem} className={styles.menuItem} />
                )}
              </li>
            );
          })}
        </ul>
        <div className={styles.secondaryContainer}>
          <ul className={clsx(styles.secondaryList, 'u-list-clean')}>
            {secondaryMenu.map(menuItem => {
              const linkPageID = menuItem.internalLink?.page[0]?.id;
              const isActive = linkPageID === currentPageId;

              return (
                <li
                  className={clsx(styles.subItem, isActive && styles.active)}
                  key={menuItem.id}
                >
                  <Icon
                    icon="arrow-right"
                    className={styles.subIcon}
                    aria-hidden
                  />
                  <NavigationItem
                    item={menuItem}
                    className={styles.secondaryItem}
                  />
                </li>
              );
            })}
          </ul>
          <div className={styles.languageSwitchContainer}>
            <LanguageSwitch />
          </div>
        </div>
      </div>
    </div>
  );
}
