import { DOMAIN_BRIDG, DOMAIN_NIMBL } from '@constants/constants';

function getNprogressColor(domain: string) {
  switch (domain) {
    case DOMAIN_NIMBL:
      return '#69dbc6';
    case DOMAIN_BRIDG:
      return '#69dbc6';
    default:
      return '#4fbb66';
  }
}

export default getNprogressColor;
