import {
  DOMAIN_ANCHR,
  DOMAIN_BRIDG,
  DOMAIN_GENERIC,
  DOMAIN_NIMBL,
} from '@constants/constants';

export function isAnchrDomain(host: string) {
  return host.includes(DOMAIN_ANCHR);
}

export function isNimblDomain(host: string) {
  return host.includes(DOMAIN_NIMBL);
}

export function isBridgDomain(host: string) {
  return host.includes(DOMAIN_BRIDG);
}

export function isGenericDomain(host: string) {
  return host.includes(DOMAIN_GENERIC);
}

export function getDomain(host: string) {
  if (isAnchrDomain(host)) {
    return DOMAIN_ANCHR;
  }

  if (isNimblDomain(host)) {
    return DOMAIN_NIMBL;
  }

  if (isBridgDomain(host)) {
    return DOMAIN_BRIDG;
  }

  return DOMAIN_GENERIC;
}
