import Icon, { IconType } from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';
import Logo from '@atoms/Logo/Logo';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import useStore from '@hooks/useStore';
import checkMenu from '@utils/checkMenu';
import clsx from 'clsx';

import styles from './Footer.module.scss';

function Footer() {
  const {
    store: { config },
  } = useStore();
  if (!config?.footer) return null;

  const {
    navigationLinks,
    contactTitle,
    contactPhone,
    contactEmail,
    informationTitle,
    informationLinks,
    linkedin,
    facebook,
    instagram,
    bottomLinks,
  } = config.footer;

  const socials = [
    { type: 'linkedin', link: linkedin },
    { type: 'facebook', link: facebook },
    { type: 'instagram', link: instagram },
  ];

  return (
    <footer className={styles.footer}>
      <div className={clsx('u-container', styles.container)}>
        <div className={styles.descriptionColumn}>
          <Link href="/" className={styles.logo} aria-label="Home">
            <Logo className={styles.logoSvg} />
          </Link>
        </div>

        {navigationLinks.length > 0 && (
          <nav className={styles.firstColumn}>
            <ul className={clsx('u-list-clean u-mb-0', styles.links)}>
              {checkMenu(navigationLinks).map(item => (
                <li key={item.id}>
                  <NavigationItem item={item} className={styles.link} />
                </li>
              ))}
            </ul>
          </nav>
        )}

        <div className={styles.secondColumn}>
          <h3 className={styles.title}>{contactTitle}</h3>
          <ul className={clsx('u-list-clean u-mb-0', styles.links)}>
            <li>
              <a
                href={`tel:${contactPhone.split(' ').join('')}`}
                className={styles.socialLink}
                title={contactPhone}
              >
                {contactPhone}
              </a>
            </li>
            <li>
              <a
                href={`mailto:${contactEmail}`}
                className={styles.socialLink}
                aria-label={contactEmail}
              >
                {contactEmail}
              </a>
            </li>
          </ul>
          <div className={styles.socialsContainer}>
            {socials.length > 0 &&
              socials.map(item =>
                item.link ? (
                  <a
                    key={item.type}
                    href={item.link}
                    className={styles.social}
                    aria-label={item.type}
                    target="_blank"
                  >
                    <Icon icon={item.type.toLowerCase() as IconType} />
                  </a>
                ) : null,
              )}
          </div>
        </div>

        {informationLinks.length > 0 && (
          <nav className={styles.lastColumn}>
            <h3 className={styles.title}>{informationTitle}</h3>
            <ul className={clsx('u-list-clean u-mb-0', styles.links)}>
              {informationLinks.map(item => (
                <li key={item.id}>
                  <NavigationItem item={item} className={styles.link} />
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>

      <div className={styles.bottomWrapper}>
        <nav className={clsx('u-container', styles.bottomContainer)}>
          <p className="u-mb-0">&copy; {new Date().getFullYear()} Anchr</p>
          {bottomLinks.length > 0 && (
            <ul className={clsx('u-list-clean u-mb-0', styles.disclaimerLinks)}>
              {bottomLinks.map(item => (
                <li key={item.id}>
                  <NavigationItem item={item} className={styles.link} />
                </li>
              ))}
            </ul>
          )}
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
